import React from 'react';
import PropTypes from 'prop-types';

import styles from './RoleBadge.module.scss';

function RoleBadge({ icon, label }) {
  return (
    <div className={`${styles.badge} flex a-center`}>
      {icon ? (
        <span className={`${styles.icon} flex center m-r-15`}>{icon}</span>
      ) : null}
      <p className="fs-14 fw-500">{label}</p>
    </div>
  );
}

RoleBadge.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
};

RoleBadge.defaultProps = {
  icon: null,
  label: null,
};

export default RoleBadge;
