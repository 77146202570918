const enumProductCategories = [
  {
    id: 1,
    key: 'tyres',
  },
  {
    id: 2,
    key: 'rims',
  },
  {
    id: 3,
    key: 'chains',
  },
];

export default enumProductCategories;
