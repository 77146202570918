import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@tanstack/react-query';

// Icons
import CrossIcon from '../../../public/icons/cross.svg';

// Components
import FlashMessage from '../../molecules/FlashMessage/FlashMessage';

// Services
import { getAlert, postConfirmReading } from '../../../src/services/client/alertService';

import styles from './Alert.module.scss';

function Alert({ overlay }) {
  const [hasAlert, setHasAlert] = useState(false);

  const getAlertQuery = useQuery(['getAlert'], getAlert);

  const postConfirmReadingMutation = useMutation(postConfirmReading, {
    onSuccess: () => setHasAlert(false),
  });

  useEffect(() => {
    if (getAlertQuery.isSuccess) {
      if (getAlertQuery.data.status === 200) setHasAlert(true);
    }
  }, [getAlertQuery.isSuccess]);

  return (
    hasAlert ? (
      <div className={`${styles.wrapper} ${overlay ? styles.overlay : ''}`}>
        <FlashMessage
          message={getAlertQuery?.data?.data?.notificationAlert?.text.replace('<p></p>', '<br>')}
          color="alert"
        />
        <button
          type="button"
          className={styles.close}
          onClick={() => postConfirmReadingMutation.mutate()}
        >
          <CrossIcon />
        </button>
      </div>
    ) : null
  );
}

Alert.propTypes = {
  overlay: PropTypes.bool.isRequired,
};

export default Alert;
