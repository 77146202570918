import api from '../_api';

// Constants
import { API } from '../../constants/routes';

export const getConnectedUser = () => api({
  method: 'get',
  url: API.USER.ME(),
});

export const postUpdateMe = (values) => api.post(
  '/user/update-me',
  values,
);

export const postUpdatePassword = (values) => api.post(
  '/user/update-password',
  values,
);

export const postAcceptTerms = (values) => api.post(
  'v1/user/accept-terms',
  values,
);
