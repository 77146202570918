/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';

// Utils
import { useAppContext } from '../store/AppContext';
import PermissionsGate, { isGranted } from '../gate/PermissionsGate';
import SCOPES from '../gate/scopes';
import displayName from '../utils/displayName';
import isAdmin from '../utils/isAdmin';

// Icons
import AdminIcon from '../../public/icons/admin/admin.svg';

// Components
import FlashMessage from '../../components/molecules/FlashMessage/FlashMessage';
import SidebarComponent from '../../components/layout/Sidebar/Sidebar';
import Cart from '../../components/layout/Cart/Cart';
import RoleBadge from '../../components/atoms/RoleBadge/RoleBadge';
import Button from '../../components/atoms/Button/Button';
import Alert from '../../components/organisms/Alert/Alert';

// Hooks
import useImpersonate from '../hooks/useImpersonate';

import styles from '../../styles/layout/SidebarLayout.module.scss';

const MobileHeader = dynamic(() => (import('../../components/layout/MobileHeader/MobileHeader')), { ssr: false });

const withSidebar = (Component, {
  overlay, activeHomeMenuEntry, cart,
}) => {
  function Sidebar(props) {
    const { t } = useTranslation();
    const isTablet = useMediaQuery({ maxWidth: 1024 });
    const [{ user, impersonateUser }] = useAppContext();
    const { handleImpersonateLogout } = useImpersonate();

    const [sidebarIsOpened, setSidebarIsOpened] = useState(false);

    const roleBadgeLabel = () => {
      if (isGranted(user, [SCOPES.canViewKeyAccountRegionalAdminLabel])) {
        return t(t('admin.keyAccountRegionalAdminView'));
      }
      if (isGranted(user, [SCOPES.canViewKeyAccountAdminLabel])) return t(t('admin.keyAccountAdminView'));
      return t('admin.adminView');
    };

    useEffect(() => {
      setSidebarIsOpened(isTablet ? sidebarIsOpened : false);
    }, [isTablet]);

    return (
      <section className={styles.layout}>
        {isTablet ? (
          <MobileHeader sidebarIsOpened={sidebarIsOpened} setSidebarIsOpened={setSidebarIsOpened} />
        ) : null}

        <div className={`${styles.sidebar} ${overlay ? styles.folded : ''} ${sidebarIsOpened ? styles.opened : ''}`}>
          <SidebarComponent overlay={overlay} activeHomeMenuEntry={activeHomeMenuEntry} />
        </div>
        <div className={`${styles.content} ${overlay ? styles.overlay : ''}`}>
          {impersonateUser?.token ? (
            <div className={`${styles.impersonate} flex a-center j-spb mobile-d-col mobile-a-start`}>
              <p
                className="m-r-20 mobile-m-r-0 mobile-m-b-20"
                dangerouslySetInnerHTML={{
                  __html: t(
                    'clients.navigateAs',
                    { userName: displayName(`${user.firstname} ${user.lastname}.`) },
                  ),
                }}
              />

              <Button
                small
                onClick={handleImpersonateLogout}
              >
                {t('clients.logoutFrom')}
              </Button>
            </div>
          ) : null}

          {user?.testModeEnabled || impersonateUser?.testModeEnabled ? (
            <div className={styles.testMode}>
              <FlashMessage message={t('clients.testModeEnabled')} color="alert" />
            </div>
          ) : null}

          <Component {...props} />

          {!isTablet ? (
            <>
              <PermissionsGate scopes={[SCOPES.canAccessCart]}>
                {cart?.showCart ? (
                  <Cart cartConfig={cart} />
                ) : null}
              </PermissionsGate>

              <PermissionsGate scopes={[SCOPES.canViewAdminDashboard]}>
                <RoleBadge icon={<AdminIcon />} label={roleBadgeLabel()} />
              </PermissionsGate>
            </>
          ) : null}

          {!isAdmin() ? (
            <Alert overlay={overlay} />
          ) : null}
        </div>
      </section>
    );
  }

  return Sidebar;
};

export default withSidebar;
