import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

// Hooks
import useDataLayer from '../../../src/hooks/useDataLayer';

// Icons
import HeadphoneIcon from '../../../public/icons/headphone.svg';

// Components
import Button from '../../atoms/Button/Button';
import CollapsibleBlock from '../../molecules/CollapsibleBlock/CollapsibleBlock';

// Utils
import useKeyAccountValues from '../../../src/hooks/useKeyAccountValues';

import styles from './Footer.module.scss';

function Footer() {
  const { t } = useTranslation();
  const { phoneNumber, saleConditions } = useKeyAccountValues();
  const { gtmEventContact } = useDataLayer();

  return (
    <div className={styles.footer}>
      <div className={styles.footer_inner}>
        <p className="m-b-10 fw-400">{t('footer.needHelp')}</p>
        <p className="m-b-20 fs-14 fw-300">{t('footer.teamAtYourService')}</p>
        <p className="m-b-20 fs-14 fw-300 flex a-center">
          <HeadphoneIcon className="m-r-10" />
          {phoneNumber}
        </p>

        <Link href="/contact">
          <Button onClick={() => gtmEventContact()} small>
            {t('footer.contactUs')}
          </Button>
        </Link>

        <div className={`${styles.legals} m-t-25 text-c-grey-600`}>
          <CollapsibleBlock label={t('footer.legals')} id="footerLegals">
            <div className="p-t-20">
              {saleConditions && (
                <Link
                  href={saleConditions}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    {t('footer.cgv')}
                </Link>
              )}

              <Link href="/legals">{t('footer.legalNotice')}</Link>
              <Link href="/privacy">{t('footer.privacyPolicy')}</Link>
            </div>
          </CollapsibleBlock>
        </div>
      </div>
    </div>
  );
}

export default Footer;
