import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'next-i18next';

// Utils
import { useAppContext } from '../../../src/store/AppContext';
import PermissionsGate, { isGranted } from '../../../src/gate/PermissionsGate';
import SCOPES from '../../../src/gate/scopes';
import displayName from '../../../src/utils/displayName';
import openModal from '../../../src/utils/modal/openModal';

// Icons
import HeadphoneIcon from '../../../public/icons/headphone.svg';
import UserIcon from '../../../public/icons/user.svg';
import ChevronDown from '../../../public/icons/chevron_down.svg';

// Hooks
import useRouter from '../../../src/hooks/useRouter';
import useMenuEntriesByRole from '../../../src/hooks/useMenuEntriesByRole';

// Components
import Badge from '../../atoms/Badge/Badge';
import Footer from '../Footer/Footer';

import styles from './Sidebar.module.scss';

function Sidebar({ overlay, activeHomeMenuEntry }) {
  const [{ user, keyAccountContext }] = useAppContext();
  const router = useRouter();
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const { t } = useTranslation();

  const [isUnfolded, setIsUnfolded] = useState(!overlay || isTablet);

  useEffect(() => {
    setIsUnfolded(!overlay || isTablet);
  }, [isTablet]);

  const menuEntries = useMenuEntriesByRole(user.roles);

  const getEntryIconLabel = (entry, withChildren) => (
    <>
      <span
        className={`
          ${styles.link_icon}
          ${!isUnfolded ? styles.unfolded_icon : ''}
          ${styles?.transition} flex m-r-10
        `}
      >
        {entry.icon}
      </span>
      {entry.badge ? <Badge theme="primary" className={styles.badge} /> : null}
      <span
        className={`
          ${styles?.transition}
          ${!isUnfolded ? styles?.hide : ''}
          ${withChildren ? 'p-r-20' : ''}
          lh-light
          fs-14 mobile-fs-16
        `}
      >
        {entry.label}
      </span>
    </>
  );

  const getUserIconLabel = () => (
    <>
      <span className={`${styles.user_icon} flex center`}>
        <UserIcon
          className={`${styles?.transition} ${!isUnfolded ? styles?.low_opacity : ''}`}
          style={{ minWidth: 24 }}
        />
      </span>
      <span className={`${styles?.transition} ${!isUnfolded ? styles?.hide : ''} m-l-10 m-r-10`}>
        {displayName(`${user.firstname} ${user.lastname.charAt(0)}.`)}
      </span>
    </>
  );

  return (
    <div
      role="presentation"
      className={`${styles.sidebar} flex d-col`}
      onMouseEnter={() => {
        if (overlay && !isTablet) setIsUnfolded(true);
      }}
      onMouseLeave={() => {
        if (overlay && !isTablet) setIsUnfolded(false);
      }}
    >
      <div className={`${styles.sidebar_inner} flex d-col flex-auto`}>
        <div
          className={`
            ${styles.inner_pad}
            ${styles.logo_wrapper}
            ${styles.transition}
            ${!isUnfolded ? styles.hide : ''}
          `}
        >
          <Link href="/" passHref>
            <div className={`${styles.logo} flex center c-pointer`}>
              {keyAccountContext?.logo ? (
                <Image width={300} height={200} alt={keyAccountContext?.label || ''} src={keyAccountContext?.logo} />
              ) : null}
            </div>
          </Link>
        </div>

        <div className={styles.menu}>
          {user?.firstname && user?.lastname ? (
            <div className={`${styles.menu_user} m-t-40 m-b-30 mobile-m-t-20 mobile-m-b-10`}>
              <div
                className={`
              ${styles.user_block}
              ${router.pathname.includes('/account') && isUnfolded ? styles.active_block : ''}
            `}
              >
                <div className={`${styles.master_entry} fs-16`}>
                  <div className="flex a-center">
                    <div
                      className={`
                    ${styles.user_entry}
                    ${router.pathname.includes('/account') && !isUnfolded ? styles.active_folded : ''}
                    flex a-center
                  `}
                    >
                      {getUserIconLabel()}
                    </div>

                    {!isTablet ? (
                      <ChevronDown
                        width={12}
                        height={6}
                        className={`${styles.arrow} m-l-auto`}
                        style={{ minWidth: 12 }}
                      />
                    ) : null}
                  </div>

                  <div className={`${styles.dropdown} flex d-col`}>
                    <PermissionsGate scopes={[SCOPES.canViewMyAccount]}>
                      <Link href={isGranted(user, [SCOPES.canViewMyAccountAsAdmin]) ? '/admin/account' : '/account'}>
                        {t('menu.account')}
                      </Link>
                    </PermissionsGate>

                    {isGranted(user, [SCOPES.canViewCenters]) ? (
                      <Link href={`/centers/edit/company?id=${user.clientId}`}>{t('menu.center')}</Link>
                    ) : null}

                    <button type="button" onClick={() => openModal('logoutConfirm')}>
                      {t('menu.logout')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="separator light" />

          <div className={`${styles.menu_more} m-t-40 m-b-40 mobile-m-t-30 mobile-m-b-30`}>
            <div className={styles.links}>
              {menuEntries.map((entry, i) =>
                entry.children?.length ? (
                  <div
                    key={i}
                    className={`
                  ${styles.link}
                  ${styles.with_children}
                  ${
                    entry.children.some((child) => router.pathname.includes(child.path)) ||
                    (activeHomeMenuEntry && entry.activeHomeMenuEntry)
                      ? styles.active
                      : null
                  }`}
                  >
                    <div className={styles.master_entry}>
                      <p className="flex a-center">
                        {getEntryIconLabel(entry, true)}
                        {!isTablet ? (
                          <ChevronDown
                            width={12}
                            height={6}
                            className={`${styles.arrow} m-l-auto`}
                            style={{ minWidth: 12 }}
                          />
                        ) : null}
                      </p>

                      <div className={`${styles.dropdown} ${styles.sub_entry} flex d-col`}>
                        {entry.children.map((child, ci) => (
                          <Link href={child.path} key={ci} data-test={child.label}>
                            {child.label}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link
                    key={i}
                    href={entry.path}
                    data-test={entry.id}
                    className={`
                    ${styles.link}
                    m-b-20 flex a-center
                    ${
                      (router.pathname === entry.path && entry.path !== '/') ||
                      (activeHomeMenuEntry && entry.activeHomeMenuEntry)
                        ? styles.active
                        : null
                    }
                  `}
                  >
                    {getEntryIconLabel(entry, false)}
                  </Link>
                ),
              )}
            </div>
          </div>
        </div>

        <PermissionsGate scopes={[SCOPES.canViewFooter]}>
          <div className={`${styles.legals} m-t-auto`}>
            <div className="separator light" />
            <div className={`${styles.transition} ${!isUnfolded ? styles.hide : ''}`}>
              <Footer />
            </div>
            <div className={`${styles.legals_min} flex center ${styles.transition} ${isUnfolded ? styles.hide : ''}`}>
              <HeadphoneIcon className={`${styles.transition} ${!isUnfolded ? styles.low_opacity : ''}`} />
            </div>
          </div>
        </PermissionsGate>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  overlay: PropTypes.bool,
  activeHomeMenuEntry: PropTypes.bool,
};

Sidebar.defaultProps = {
  overlay: false,
  activeHomeMenuEntry: false,
};

export default Sidebar;
